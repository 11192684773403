$primary: #c41d29; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Architects+Daughter|Kameron');
// font-family: 'Architects Daughter', cursive;
// font-family: 'Kameron', serif;

h2, h3 {
	font-family: 'Kameron', serif;
	
}
 
 p { 
 	font-family: 'Architects Daughter', cursive;
 }

html,body {
  height: 100%;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	background-color: #f6e16e;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	.navbar-nav {

	    > li > a {
	    	font-family: 'Kameron', serif;
	    	border-radius: $border-radius;
			text-align: center;
			margin-top: 20px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
			    display: inline-block;
			}

			@media(max-width:640px) and (orientation: landscape) {
				margin-top: -8px;
			}

			@media(max-width: 568px) and (orientation: landscape) {
				margin-top: -8px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;

		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

    @media (max-width: 767px) {
    	 margin: 15px 15px 8px 0px;
    }
    
    @media(max-width: 568px) and (orientation: landscape) {
		margin: 12px 15px 8px 0px;
	}

}


/** LOGIN FORM **/
@mixin btn {
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
		text-decoration: none;
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}

	    input {
            color: #000;
        }
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 50%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
footer {
	padding: 40px 0px 20px;
	background: #f6e16e;
	color: $blk;
	font-size: 15px;


	a {
		color: $blk;
		white-space: nowrap;
		font-size: 15px;
		
		@media (max-width: 990px) {
			font-size: 13px;
		}
		
		&:hover{
			color: lighten($blk, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $blk;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;
		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


/* Gradient transparent - color - transparent */

hr.stylethis {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.logo {
	max-width: 200px;
	padding: 0.5em;

	@media (max-width: 767px){
		max-width: 175px;
	}

	@media (max-width: 568px) and (orientation: landscape){
		max-width: 125px;
	}

	@media (max-width: 320px){
		max-width: 140px;
	}
}

@media(max-width: 767px) {
    .navbar-header a {
        float: left;
    }
}

.intro {
	background: -webkit-linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 25%;
	padding: 250px 0px;


	@media (max-width: 767px){
		padding: 100px 0px;
	}


	h1{
		font-family: 'Kameron', serif;
		font-size: 40px;
		color:$wht;
		text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
	}

	p{
		font-size: 16px;
		color:$primary;
	}

}

#circle {
    width: 225px;
    height: 225px;
    // background: red;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;
	// border: 4px solid #000;
	box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.75), 0px 1px 10px 1px rgba(0, 0, 0, 0.75);

    @media (max-width: 767px) {

    	width: 200px;
    	height: 200px;
    // background: red;
    	-moz-border-radius: 150px;
    	-webkit-border-radius: 150px;
    	border-radius: 150px;
    }
}

.circle {
	padding: 5px;

	@media(max-width: 991px) {
		padding: 25px 0px;		
	}
}

.divider {
	background-color:#fff;

	h1 {
		font-family: 'Kameron', serif;
		font-size: 35px;
		color:$primary;
		
		@media (max-width: 1366px) and (orientation:landscape){
			font-size: 30px;
		}

		@media (max-width: 767px){
			font-size: 20px;
		}
	}

	p {
		padding: 10px;
		font-size: 18px;
		color:$blk;

		@media (max-width: 767px){
			font-size: 13px;
		}
	}
}

.form {

		i {
		margin-top: 32px;
		font-size: 8em;
		color: rgba(255, 255, 255, 1);
		text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);

		@media (max-width: 767px){
			margin-top: 40px;
    		font-size: 95px;
    	}
	}

	h1 {
		font-family: 'Alfa Slab One', cursive;
		margin-top: 3px;
		font-size: 28px;
		color: rgba(255, 255, 255, 1);
		text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	.img1 {

    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #fff;
	}

	.img2 {

    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #fff;

	}

	.img3 {

    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #fff;

	}
	.img4 {

    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 10%, rgba(0, 0, 0, 0.0) 10%, #f6e16e 100%), url(../img/soccer-ball.png) no-repeat;
    height: 300px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    // padding: 300px 20px;
    /* background-position: 11% 20%; */
    color: #fff;
    background-color: #fff;

	}
}

.form,.img1,.img2,.img3,.img4 {
	h1 {
		font-family: 'Kameron', serif;
		font-size: 20px;
		color:$blk;
	
		@media (min-width: 768px) and (max-width: 1199px){
			font-size: 14px;
		}
	}
}

.info {

		padding: 0px 0px;

		@media (max-width: 767px){
			margin-top: -50px;
		}

	h1 {
		font-family: 'Kameron', serif;
		font-size: 35px;
		color:$primary;

		@media (max-width: 767px){
			font-size: 20px;
		}
	}

	p {
		font-size: 18px;
		color:$blk;
		line-height:25px;

		@media (max-width: 767px){
			font-size: 13px;
		}
	}
}

.btn {
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

 	&:hover {
 		background-color: #f6e16e; 
 		//border-color: rgba(255,255,255,1); 
 		color: $blk;
		@media (max-width: 1366px) {
			background-color: $primary;
			color: $wht;
		}

	&:active, &:focus {
		background-color: $primary;
		color: $wht !important;
		}
	}
}

.outro {
	background: -webkit-linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../img/banner2.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(../img/banner2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 11%;
	padding: 250px 0px;

	@media (max-width: 767px){
		padding: 100px 0px;
	}

	h1{
		font-family: 'Kameron', serif;
		font-size: 40px;
		color:$wht;
		text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
	}

	p{
		font-size: 16px;
		color:$primary;
	}
}

@media (min-width: 1200px){
	.spacer {
		margin-top:40px;
	}
}


@media (min-width:768px) and (max-width: 1199px){
	#circle {
		width: 150px;
    	height: 150px;

	}

	.form i {
    	font-size: 5em;

	}
}
